import React, { useState } from 'react';
import { HiCheck, HiX } from 'react-icons/hi';
import * as Checkbox from '@radix-ui/react-checkbox';
import * as Label from '@radix-ui/react-label';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { format, parse } from 'date-fns';
import { baseUrl, headers } from '../../app/lookups';
import AlertMessage from '../../components/AlertMessage';
import { useAPI } from '../../app/apiContext';
import Form from '../../components/dynamic-form/Form';

export default function CreateNewProject({ setShowModal }) {
  const location = useLocation();
  const [addProjectResponse, setAddProjectResponse] = useState(null);
  const [saveFieldsOnSubmit, setSaveFieldsOnSubmit] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [atamisDisplayAlert, setAtamisDisplayAlert] = useState(false);

  const [formDisplayAlert, setFormDisplayAlert] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const { fetchAllProjects } = useAPI();

  const navigate = useNavigate();

  const ics = sessionStorage.getItem('ics');

  const form = useForm({
    defaultValues: {
      title: '',
      trust: '',
      division: '',
      dept: '',
      projectRef: '',
      cipCategory: '',
      projectLead: '',
      status: '',
      cipTarget: 0,
      financialYear: '',
      startDate: null,
      anticipatedDelivery: null,
      isRecurrent: null,
      actualDelivery: null,
      contractValue: 0,
      usedPd: null,
      savingsByMonth: [],
      cipRef: '',
      approvedBy: '',
      notes: '',
      inYearSaving: 0,
      carryOver: 0,
      commercialStrategy: '',
      routeToMarket: '',
      cmClassification: '',
      mainCpvCategory: '',
      mainAlternativeCategory: '',
      contractDuration: 0,
      estimatedExtensionLength: 0,
      estimatedExtensionValue: 0,
      savingsType: '',
      savingsCategory: '',
      procurementRoute: '',
      contractType: '',
      existingSupplier: '',
      existingContractEndDate: null,
      cyberSecurityCertification: '',
      cyberSecurityDecisionRationale: '',
    },
  });

  const handleClearForm = () => {
    form.reset({
      title: '',
      trust: '',
      division: '',
      dept: '',
      projectRef: '',
      cipCategory: '',
      projectLead: '',
      status: '',
      cipTarget: 0,
      financialYear: '',
      startDate: '',
      anticipatedDelivery: '',
      isRecurrent: null,
      actualDelivery: '',
      contractValue: 0,
      usedPd: null,
      savingsByMonth: [],
      cipRef: '',
      approvedBy: '',
      notes: '',
      inYearSaving: 0,
      carryOver: 0,
      commercialStrategy: '',
      routeToMarket: '',
      cmClassification: '',
      mainCpvCategory: '',
      mainAlternativeCategory: '',
      contractDuration: 0,
      estimatedExtensionLength: 0,
      estimatedExtensionValue: 0,
      savingsType: '',
      savingsCategory: '',
      publicProcurementRegulations: '',
      procurementRoute: '',
      contractType: '',
      existingSupplier: '',
      existingContractEndDate: null,
      cyberSecurityCertification: '',
      cyberSecurityDecisionRationale: '',
    });
    setSaveFieldsOnSubmit(false);
  };

  const onError = (errors) => {
    setFormErrors(errors);
    // eslint-disable-next-line no-console
    console.log(formErrors);
    setFormDisplayAlert(true);
  };

  const onSubmit = async (data) => {
    await form.trigger();
    if (form.formState.isValid) {
      const responseData = await axios.post(
        `${baseUrl}/workplan/project`,
        {
          title: data.title,
          financial_year: data.financialYear,
          trust: data.trust,
          division: data.division,
          dept: data.dept,
          project_ref: data.projectRef,
          cip_category: data.cipCategory,
          project_lead: data.projectLead,
          status: data.status,
          cip_target: data.cipTarget,
          start_date:
            data.startDate === null ||
            data.startDate === '0000-00-00' ||
            data.startDate === ''
              ? null
              : format(
                  parse(data.startDate, 'dd/MM/yyyy', new Date()),
                  'yyyy-MM-dd',
                ),
          anticipated_delivery:
            data.anticipatedDelivery === null ||
            data.anticipatedDelivery === '0000-00-00' ||
            data.anticipatedDelivery === ''
              ? null
              : format(
                  parse(data.anticipatedDelivery, 'dd/MM/yyyy', new Date()),
                  'yyyy-MM-dd',
                ),
          is_recurrent: data.isRecurrent,
          actual_delivery:
            data.actualDelivery === null ||
            data.actualDelivery === '0000-00-00' ||
            data.actualDelivery === ''
              ? null
              : format(
                  parse(data.actualDelivery, 'dd/MM/yyyy', new Date()),
                  'yyyy-MM-dd',
                ),
          contract_value: data.contractValue,
          used_pd: data.usedPd,
          savings_by_month: data.savingsByMonth,
          cip_ref: data.cipRef,
          approved_by: data.approvedBy,
          notes: data.notes,
          current_year_saving: data.inYearSaving,
          next_year_saving: data.carryOver,
          ics,
          action: 'CREATE',
          updated_by: sessionStorage.getItem('name'),
          date_updated: new Date(),
          savings_breakdown:
            data.savingsByMonth.length === 0
              ? ''
              : JSON.stringify(data.savingsByMonth),
          commercial_strategy: data.commercialStrategy,
          route_to_market: data.routeToMarket,
          cm_classification: data.cmClassification,
          main_cpv_category: data.mainCpvCategory,
          main_alternative_category: data.mainAlternativeCategory,
          contract_duration: data.contractDuration,
          estimated_extension_length: data.estimatedExtensionLength,
          estimated_extension_value: data.estimatedExtensionValue,
          savings_type: data.savingsType,
          savings_category: data.savingsCategory,
          public_procurement_regulations: data.publicProcurementRegulations,
          procurement_route: data.procurementRoute,
          contract_type: data.contractType,
          existing_supplier: data.existingSupplier,
          existing_contract_end_date:
            data.existingContractEndDate === null ||
            data.existingContractEndDate === '0000-00-00' ||
            data.existingContractEndDate === ''
              ? null
              : format(
                  parse(data.existingContractEndDate, 'dd/MM/yyyy', new Date()),
                  'yyyy-MM-dd',
                ),
          cyber_security_certification: data.cyberSecurityCertification,
          cyber_security_rationale: data.cyberSecurityDecisionRationale,
        },
        { headers },
      );
      fetchAllProjects();
      setAddProjectResponse(responseData.data);
      setDisplayAlert(true);
      setAtamisDisplayAlert(true);

      if (!saveFieldsOnSubmit) {
        handleClearForm();
      }
    } else {
      setFormErrors(form.formState.errors);
    }
  };

  return (
    <>
      <div className="flex items-start justify-between p-5 border-b border-solid border-platform-primary-grey-200 rounded-t">
        <div className="text-3xl font-bold text-platform-primary-grey-800">
          Create New Project
        </div>
        {location.pathname === '/new' ? (
          <button
            type="button"
            aria-label="home-button"
            className="border-2 rounded-md p-2 font-bold bg-platform-cta-error-800  border-platform-cta-error-800 text-white hover:bg-white hover:text-platform-cta-error-800"
            onClick={() => navigate('/home')}>
            <HiX />
          </button>
        ) : (
          <button
            type="button"
            aria-label="modal-button"
            className="border-2 rounded-md p-2 font-bold bg-platform-cta-error-800  border-platform-cta-error-800 text-white hover:bg-white hover:text-platform-cta-error-800"
            onClick={() => setShowModal(false)}>
            <HiX />
          </button>
        )}
      </div>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit, onError)}>
          <div className="relative p-6 flex-auto">
            <Form />
          </div>
          <div className="p-4 border-t border-solid border-platform-primary-grey-200 rounded-b">
            {formDisplayAlert && (
              <AlertMessage
                success={false}
                message="Please complete all required fields or ensure the provided information is accurate"
                closeAlert={() => setFormDisplayAlert(false)}
              />
            )}
            {displayAlert && addProjectResponse.create.success === 'true' ? (
              <AlertMessage
                success
                message={`Project has been created with id ${addProjectResponse.create.project_id}`}
                closeAlert={() => setDisplayAlert(false)}
              />
            ) : displayAlert ? (
              <AlertMessage
                success={false}
                message="Project creation failed"
                closeAlert={() => setDisplayAlert(false)}
              />
            ) : null}
            {atamisDisplayAlert &&
            addProjectResponse?.create?.atamis_workplan_id !== undefined ? (
              <AlertMessage
                success
                message={`Project has been created in Atamis with id ${addProjectResponse.create.atamis_workplan_id}`}
                closeAlert={() => setAtamisDisplayAlert(false)}
              />
            ) : atamisDisplayAlert ? (
              <AlertMessage
                success={false}
                message="Project creation failed in Atamis, please contact us at support@adviseinc.co.uk"
                closeAlert={() => setAtamisDisplayAlert(false)}
              />
            ) : null}
            <div className="flex flex-col pt-2">
              <div className="flex mb-2">
                <Checkbox.Root
                  className="flex h-[16px] w-[16px] appearance-none items-center justify-center rounded-md border border-platform-primary-grey-200 text-white focus:ring-0 data-[state=checked]:border-platform-primary-orange-800 data-[state=checked]:bg-platform-primary-orange-800"
                  id="saveFields"
                  checked={saveFieldsOnSubmit}
                  onCheckedChange={() =>
                    setSaveFieldsOnSubmit(!saveFieldsOnSubmit)
                  }>
                  <Checkbox.Indicator className="">
                    <HiCheck />
                  </Checkbox.Indicator>
                </Checkbox.Root>
                <Label.Root
                  className="block items-center uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold mx-2 mb-2"
                  htmlFor="saveFields">
                  Save fields on submit?
                </Label.Root>
              </div>
              <div className="flex justify-between">
                <button
                  className="mx-1 bg-platform-cta-error-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-error-800 hover:border-platform-cta-error-800 border-1"
                  type="button"
                  onClick={() => handleClearForm()}>
                  Clear Form
                </button>
                <button
                  className="mx-1 bg-platform-cta-success-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-success-800 hover:border-platform-cta-success-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
                  type="submit">
                  Create Project
                </button>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
